<template>
    <div
        class="row mb-3 font-medium font-20 justify-center text-xs-center"
        v-if="title"
    >
        {{ title }}
    </div>
    <DxValidationGroup ref="formValidation">
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="TenTK"
                    label="Tên tài khoản"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    class="matkhau-style"
                    :readOnly="true"
                >
                </DxTextBox>
            </div>
        </div>
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="MatKhau"
                    label="Mật khẩu (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :mode="pmMatKhau"
                    class="matkhau-style"
                    :tabIndex="1"
                >
                    <DxTextBoxButton
                        :options="pbMatKhau"
                        name="password"
                        location="after"
                    />
                    <DxValidator>
                        <DxRequiredRule
                            message="Mật khẩu không được bỏ trống!"
                        />
                        <!-- <DxCompareRule
                            :comparison-target="
                                () => {
                                    return NhapLaiMatKhau;
                                }
                            "
                            message="Mật khẩu và Nhập lại mật khẩu không khớp!"
                        /> -->
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
        <div class="row mb-4">
            <div class="xs12">
                <DxTextBox
                    v-model="NhapLaiMatKhau"
                    label="Nhập lại mật khẩu (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :mode="pmNhapLaiMatKhau"
                    class="matkhau-style"
                    :tabIndex="2"
                >
                    <DxTextBoxButton
                        :options="pbNhapLaiMatKhau"
                        name="password"
                        location="after"
                    />
                    <DxValidator>
                        <DxRequiredRule
                            message="Nhập lại mật khẩu không được bỏ trống!"
                        />
                        <DxCompareRule
                            :comparison-target="
                                () => {
                                    return MatKhau;
                                }
                            "
                            message="Nhập lại mật khẩu và Mật khẩu không khớp!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
    </DxValidationGroup>
    <div class="row justify-space-between">
        <DxButton
            text="Hủy"
            type="default"
            styling-mode="text"
            @click="Huy()"
        />
        <DxButton
            text="Xác nhận"
            type="default"
            styling-mode="contained"
            @click="XacNhan()"
        />
    </div>
</template>

<script>
import { DxButton, DxValidationGroup, DxValidator } from "devextreme-vue";
import { DxCompareRule, DxRequiredRule } from "devextreme-vue/data-grid";
import {
    DxTextBox,
    DxButton as DxTextBoxButton,
} from "devextreme-vue/text-box";
export default {
    components: {
        DxValidator,
        DxRequiredRule,
        DxTextBox,
        DxButton,
        DxTextBoxButton,
        DxValidationGroup,
        DxCompareRule,
    },
    props: {
        title: { type: String, default: null },
        TenTaiKhoan: { type: String, default: null },
    },
    data() {
        return {
            TenTK: this.TenTaiKhoan,
            MatKhau: null,
            NhapLaiMatKhau: null,
            pmMatKhau: "password",
            pbMatKhau: {
                icon: "mdi mdi-eye",
                type: "normal",
                onClick: () => {
                    this.pmMatKhau =
                        this.pmMatKhau === "text" ? "password" : "text";
                },
            },
            pmNhapLaiMatKhau: "password",
            pbNhapLaiMatKhau: {
                icon: "mdi mdi-eye",
                type: "normal",
                onClick: () => {
                    this.pmNhapLaiMatKhau =
                        this.pmNhapLaiMatKhau === "text" ? "password" : "text";
                },
            },
        };
    },
    watch: {},
    methods: {
        Huy() {
            this.$emit("Huy");
        },
        XacNhan() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                this.$emit("XacNhanDatMatKhau", this.MatKhau);
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
    },
};
</script>

<style scoped></style>
